<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> 
            <!-- <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> -->
            <span v-if="options.headerTitle" class="f-17 f-bold m-l-12 c-black">{{ options.headerTitle }}</span>
        </div>
        <!-- <div class="set-title-box"> -->
            <div class="title-box f-17 f-bold m-l-28 m-r-28 m-t-24">
                <!-- <div class="title f-17" v-if="options.mode && options.mode === 'modify'"> -->
                <div class="title f-17">
                    {{ title || options.storyTitle}}
                </div>
                <!-- <div class="title f-17" v-if="!options.mode"> {{ options.storyTitle }} </div> -->
                <img class="modify-title m-r-12" @click="onClickChange()" :src="require(`@/assets/images/icons/modify_story.png`)" alt="modify-title-icon" width="20px" height="20ox">
            </div>

            <!-- <div class="modify-box">
                <img class="modify-title" :src="require(`@/assets/images/icons/modify_story.png`)" alt="modify-title-icon" width="18px" height="18ox">
            </div> -->
        <!-- </div> -->
        <div class="story-content m-t-12 m-b-0 m-l-20 m-r-20">
            <TextareaWithX @input="onInputComment" :textareaRows="rowCount" :placeholder="placeholder" v-model="inputContent" :ref="'comment'" :setClass="'content-area m-0'"/>
        </div>
        <div class="write-more f-13 m-l-26 m-t-8 m-r-26" v-if="lessWordCount">
            답변 길이가 짧아요. 성의가 없어 보일 수 있어요.
            <br> <br>
            단답형 보다는 문장형('-요', '-다'로 끝나는 형태)으로 답변을 작성해주시는게 좋습니다.
        </div>
        <div class="save-story">
            <button class="save-button" :disabled="disabled" @click="onClickSave"> 답변 저장하기 </button>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'

export default {
    name: 'ModalStoryWriter',
    props: ['options'],
    data: () => ({
        showSearch: true,
        buttonDisabled: false,
        inputContent: null,
        rowCount: 4,
        changedSelVal: null,
        selectedVal: null,
        title: ''
    }),
    mounted() {
        this.setInputContent()
        this.onInputComment()
        this.setTitle()
    },
    computed: {
        essentialFunnel() {
            const fullPath = this.$route.fullPath.split('/')
            return fullPath.includes('signup')
        },
        section() {
            return this.$sections()
        },
        placeholder() {
            return '진부한 이야기 말고, 나의 이야기와 생각을 들려주세요.'
        },
        lessWordCount() {
            if (this.inputContent && this.inputContent.length < 17) {
                return true
            } else {
                return false
            }
        },
        disabled() {
            if (this.inputContent) {
                return false
            } else {
                return true
            }
        },
    },
    methods: {
        setTitle() {
            if (this.options.userPersonalStory) {
                this.title = this.options.stories[this.options.userPersonalStory.personal_story_id - 1].name
            } else {
                this.title = this.options.storyTitle
            }
        },
        setInputContent() {
            if (this.options.story) {
                this.inputContent = this.options.userPersonalStory.content
            } else if (this.options.mode && this.options.mode === 'modify') {
                this.inputContent = this.options.userPersonalStory.content
            }
        },
        onInputComment() {
            const rowCount = Math.ceil( this.inputContent.length / (this.$refs.comment.$refs.textarea.clientWidth / 15))

            if (rowCount <= 5) {
                this.rowCount = 4
            } else {
                this.rowCount = rowCount - 1
            }
        },
        async onClickClose() {
            if (this.options.mode && this.options.userPersonalStory.content == '') {
                this.$emit('close')
                // this.onClickChange()
                
            } else {
                // const idx = await this.$modal.basic({
                //     title: '지금 페이지를 나가면 작성한 내용이 저장되지 않습니다.',
                //     body: '이전 페이지로 이동하시겠습니까?',
                //     buttons: [
                //         {
                //             label: 'CANCEL',
                //             class: 'btn-default',
                //         },
                //         {
                //             label: 'CONFIRM',
                //             class: 'btn-primary',
                //         },
                //     ],
                // })
                // if (idx) {
                const params = {storyId: this.options.storyId}
                this.$emit('close', params)
                // }
            }

            // this.$modal.custom({
            //     component: 'ModalStorySelector',
            //     options: {
            //         profile: this.options.profile,
            //         storyId: this.options.storyId,
            //         headerTitle: '퍼스널 스토리 작성하기',
            //         // story: userPersonalStory,
            //     },
            // })
        },
        async onClickSave() {
            try {
                if (this.$store.getters.me.profile.status !== 'created' && this.options.mode === 'modify') {
                    const idx = await this.$modal.basic({
                        title: '수정하시겠습니까?',
                        body: '기존에 작성하신 퍼스널 스토리는 삭제됩니다.',
                        buttons: [
                            {
                                label: '아니오',
                                class: 'btn-default',
                            },
                            {
                                label: '네',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        const story = this.options.stories.filter(o => o.name === this.title)

                        const payload = {
                            user_id: this.$store.getters.me.id,
                            story_id: story[0].id,
                            ups_id: this.options.userPersonalStory.id,
                            content: this.inputContent,
                        }
                        await profileService.updateUserPersonalStories(payload)
                        await this.$store.dispatch('loadProfile')
                        await this.$toast.success("수정되었습니다.")
                    }
                } else if (this.options.userPersonalStory) {
                    const payload = {
                        user_id: this.$store.getters.me.id,
                        story_id: this.options.userPersonalStory.personal_story_id,
                        ups_id: this.options.userPersonalStory.id,
                        content: this.inputContent,
                    }
                    await profileService.updateUserPersonalStories(payload)
                    await this.$store.dispatch('loadProfile')
                } else {
                    const payload = {
                        user_id: this.$store.getters.me.id,
                        story_id: this.options.storyId,
                        content: this.inputContent,
                    }
                    await profileService.createUserPersonalStories(payload)
                    await this.$store.dispatch('loadProfile')
                    // this.$stackRouter.pop()
                }
                const params = {
                    storyId: this.options.storyId,
                    msg: 'storyComplete'
                }
                this.$emit('close', params)
                // this.$emit('closeAll')
            } catch (e) {
                console.log(e)
            }
        },

        async onClickChange() {
            if (this.options.mode && this.options.mode === 'modify') {
                const personalStories = await profileService.personalStories()
                const value = await this.$modal.custom({
                    component: 'ModalStorySelector',

                    options: {
                        profile: this.options.profile,
                        headerTitle: "질문 선택하기",
                        stories: personalStories,
                        userPersonalStory: this.options.userPersonalStory,
                        storyId: this.options.storyId,
                    },
                })
                if (this.options.userPersonalStory && this.options.userPersonalStory.personal_story_id !== value.id) {
                    // this.options.userPersonalStory.personal_story_id = value.id
                    // this.options.userPersonalStory.content = ''
                    this.inputContent = ''
                    this.title = value.name
                    this.selectedVal = value
                } else if (this.options.storyId && this.options.storyId !== value.id) {
                    this.options.storyId = value.id
                    this.options.storyTitle = value.name
                    this.setTitle()
                }
            } else {
                // const idx = await this.$modal.basic({
                //     body: '새로운 질문을 선택하면 작성된 답변이 초기화됩니다.<br>새로운 질문으로 바꾸시겠습니까?',
                //     buttons: [
                //         {
                //             label: 'CANCEL',
                //             class: 'btn-default',
                //         },
                //         {
                //             label: 'CONFIRM',
                //             class: 'btn-primary',
                //         },
                //     ],
                // })
                // if (idx) {
                    const params = { storyId: this.options.storyId, msg: null }
                    // this.$emit('close')
                    this.$emit('close', params)
                // }
            }
        }
    },
}
</script>
<style scoped lang="scss">
.back-button-wrapper {
    position: relative;
    background: #FAF0E1;
    justify-content: center;

    i {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 20px;
    }
}

.title-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #E8E4E7;
    border-radius: 8px;

    .title {
        text-align: left !important;
        color: #151360;
        margin: 13px 41px 13px 12px;
    }

    img {
        color: #151360;
    }
}

.modify-box {
    img {
        position: absolute;
        float: right;
    }
}

.story-content {
    color: #5C5C77;
    border: 1px #5C5C77;
    
    textarea {
        font-size: 15px;
        line-height: 22.5px;
    }
}

.write-more {
    color: #5C5C77;
}

.save-story {
    position: absolute;
    padding: 0 20px 0 20px;
    bottom: 16px;
    width: 100%;
    
    .save-button {
        width: 100%;
        height: 48px;
        border-radius: 36px;
        background-color: #151360;
        color: #F7F7F4;
        
        &[disabled],
        &.disabled {
            border: none;
            background: #CAC4D3;
            box-shadow: none;
            color: #FFFFFF;
            @include f-medium;
        }
    }
}
</style>